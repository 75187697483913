<template>
  <div class='plk_export'>
    <Menu></Menu>
    <div class='export'>
      <h5>{{$t('designer.export.titlepage')}}</h5>
      <span>{{$t('designer.export.description')}}</span>
      <form @submit.prevent="download" class="mt-3">
        <label for="fileName">{{$t('designer.export.filenametitle')}}</label>
        <input required v-model="fileName" class="u-full-width" type="text" :placeholder="$t('designer.export.filename')" id="fileName">
        <button type="submit" :disabled="isLoading" class="w-100">{{ isLoading ? $t('designer.export.generating') : $t('designer.export.generate') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { APP_FOLDER_PATH } from '@/enviroment/app'
import { Store, DESIGN } from '@/store'
import i18n from './i18n'
import Menu from './components/menus/Menu'

export default {
  name: 'export',
  components: {
    Menu
  },
  data () {
    return {
      fileName: '',
      isLoading: false
    }
  },
  computed: {
    views () {
      return Store.getters[DESIGN.GETTERS.GET_VIEWS]()
    },
    files () {
      return Store.getters[DESIGN.GETTERS.GET_ALL_FILES]()
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
    },
    searchPath (zip, fileName) {
      const fileKey = Object.keys(zip.files).find(e => e.match(new RegExp(`([^/]*/|)${fileName}`, 'gi')))
      return fileKey || `${APP_FOLDER_PATH}/${fileName}`
    },
    getContent (filename) {
      let content = Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](filename) || {}
      if (content.isImage) {
        return { data: content.data.substr(`${content.data.split(',/')[0]},`.length), params: { base64: true } }
      } else if (content.data.length) {
        return { data: content.data }
      } else {
        return undefined
      }
    },
    async download () {
      try {
        Vue.set(this, 'isLoading', true)
        const response = await this.$servicesPLK.component.loadZip()
        const zip = await JSZip.loadAsync(response)
        this.files.forEach(file => {
          const filePath = `${APP_FOLDER_PATH}/${file}`
          const content = this.getContent(file)
          if (content) {
            zip.remove(filePath)
            zip.file(filePath, content.data, content.params)
          }
        })
        const viewsFolder = this.searchPath(zip, 'views')
        zip.remove(viewsFolder)
        zip.folder(viewsFolder)
        Object.keys(this.views).forEach(id => {
          zip.file(this.searchPath(zip, `views/${id}.json`), JSON.stringify(this.views[id] || {}, null, 2))
        })
        const content = await zip.folder(APP_FOLDER_PATH).generateAsync({ type: 'blob' })
        await saveAs(content, `${this.fileName}.zip`)
        Vue.set(this, 'isLoading', false)
        Vue.set(this, 'fileName', '')
        const msg = this.$t('designer.export.ok2generate')
        this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, { type: 'success', msg })
      } catch (e) {
        console.error(e)
        const msg = this.$t('designer.export.error2generate')
        this.$servicesPLK.event.emitEvent(this.$servicesPLK.event.BASIC_ACTIONS.ERROR, { type: 'error', msg })
      }
    }
  }
}
</script>
<style scoped lang='scss'>
  .plk_export {
    .export {
      padding: 3vh;
      padding-left: calc(3vh + 50px);
    }
  }
</style>
